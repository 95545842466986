<template>
  <div class="relative">
    <template>
      <h2 class="text-2xl mb-2 text-gray-600">
        {{ isUpdating ? 'Actualizar' : 'Crear' }} bitácora
      </h2>
      <form @submit.prevent="isUpdating ? handleUpdate(binnacle) : handleStore(binnacle)" @keydown="removeError($event.target.id)">
        <div class="w-full">

          <a-input
            label="Fecha de la incidencia"
            type="date"
            id="incidence_date"
            :error="error('incidence_date')"
            v-model="binnacle.incidence_date" />

          <a-select
            label="Profesor"
            class="mt-4"
            v-if="isAdmin"
            @input="removeError('teacher_id')"
            :loading="loadingTeachers"
            :source="teachers.map(el => ({ label: `${el.first_name} ${el.last_name}`, value: el.id }))"
            :error="error('teacher_id')"
            v-model="binnacle.teacher_id"/>

          <a-select
            label="Materia"
            class="mt-4"
            @input="removeError('course_section_id')"
            :loading="localLoading"
            :source="teacherCourses"
            :error="error('course_section_id')"
            v-model="binnacle.course_section_id"/>

          <a-input
            label="Descripción"
            class="mt-4"
            id="description"
            :error="error('description')"
            v-model="binnacle.description" />
        </div>

        <div class="flex space-x-2 mt-2">
          <a-button mode="primary" type="submit" :disabled="loading" :loading="loading">
            {{ isUpdating ? 'Actualizar cambios' : 'Guardar cambios' }}
          </a-button>

          <a-button outlined @click="close">
            Cancelar
          </a-button>
        </div>
      </form>
    </template>

    <div class="absolute backdrop-filter backdrop-blur-md -inset-4 flex justify-center items-center rounded-xl" v-if="!period.value">
      <h1 class="text-2xl">
        No hay un periodo activo actualmente
      </h1>
    </div>
  </div>
</template>

<script>
import { mapState, mapActions, mapGetters } from 'vuex';
export default {
  props: {
    student: { required: false, type: Object, default: null },
    updateData: { required: false, type: Object, default: null },
    course: { required: false, type: [String, Number], default: null },
    period: { required: false, type: Object, default: () => ({ id: null }) }
  },
  data: () => ({
    binnacle: {
      incidence_date: '',
      description: '',
      course_section_id: null,
      student_id: null,
      teacher_id: null,
      modal:true
    },
    isUpdating: false,
    courses: [],
    localLoading: false
  }),
  computed: {
    ...mapGetters({
      hasError: 'hasError',
      error: 'error',
      isTeacher: 'session/isTeacher',
      isAdmin: 'session/isAdmin',
    }),
    ...mapState({
      binnacles: state => state.binnacles.pagination.data,
      currentBinnacleOnState: state => state.binnacles.current,
      loading: state => state.binnacles.loading,
      students: state => state.students.all,
      loadingStudents: state => state.students.loading,
      teachers: state => state.teachers.all,
      loadingTeachers: state => state.teachers.loading,
      courseSections: state => state.courseSections.all,
      session: state => state.session.user,
      teacher: state => state.teacher.teacher,
      user: state => state.session.user,
    }),
    teacherCourses() {
      let teacher = null
      if (this.user.isTeacherGroup) {
        return this.courses
          .filter($0 => $0.period_id === this.period.value)
          .map(it => ({ label: `${it.course.title} - ${it.section.letter_code}`, value: it.id }))
      }

      if (this.user.isAdmin) {
        teacher = this.teachers.find(it => it.id === this.binnacle.teacher_id)
      }

      // console.log(teacher)

      if (teacher) {
        return teacher.course_sections
          .filter($0 => $0.period_id === this.period.value)
          .filter($0 => $0.students.some($1 => $1.id === this.student.id))
          .map(it => ({ label: `${it.course.title} - ${it.section.letter_code}`, value: it.id }))
      }
      // teacher = this.teachers.find(it => it.id === this.binnacle.teacher_id)
      return []
    },
    mappedStudents() {

      let students = []

      if (!this.binnacle.teacher_id) {
        return []
      }

      if (!this.binnacle.course_section_id) {
        return []
      }

      this.teachers.find($0 => $0.id === this.binnacle.teacher_id).course_sections.forEach($0 => {
        if (this.binnacle.course_section_id === $0.id) {
          $0.students.forEach($1 => {
            if (!students.some($2 => $2.id === $1.id)) {
              students.push($1)
            }
          })
        }
      })

      return students.map($0 => ({ label: `${$0.first_name} ${$0.last_name}`, value: $0.id }))
    }
  },
  methods: {
    ...mapActions({
      fetchBinnacle: 'binnacles/show',
      store: 'binnacles/store',
      update: 'binnacles/update',
      removeError: 'removeError',
      fetchStudents: 'students/index',
      fetchTeachers: 'teachers/index',
      fetchCourseSections: 'courseSections/index',
      fetchTeacher: 'teachers/show'
    }),
    handleStore(data) {
      this.store(data)
        .then(({ data }) => {
          this.$emit('created', data)
        })
    },
    handleUpdate({ incidence_date, description, student_id, id }) {
      this.update({ incidence_date, description, student_id, id })
        .then(({ data }) => {
          this.$emit('updated', data)
        })
    },
    close() {
      this.$emit('close', event.target.value)
    }
  },
  mounted() {
    this.binnacle.student_id = this.student.id;
    if (this.isAdmin) {
      this.fetchStudents({ limit: 0, enabled: true })
      this.fetchTeachers({ limit: 0, enabled: true, with: 'courseSections.course,courseSections.section,courseSections.students' })
    } else if (this.user.isTeacherGroup) {
      this.localLoading = !this.localLoading
      this.$repository.teachers
        .courses(this.user.teacher.id, { limit: 0, with: 'section,students' })
        .then(res => {
          this.courses = res.data
        })
        .finally(() => this.localLoading = !this.localLoading)
    }


    if (this.updateData !== null) {
      this.isUpdating = true
      this.binnacle = {...this.updateData}
    }

  }
}
</script>
