<template>
  <div class="p-4">
    <div v-if="!showBinnacles">
      <modal-binnacles
        @updated="handleUpdateOrCreate"
        @created="handleUpdateOrCreate"
        :period="enabledPeriod"
        :course="$route.params.course_id"
        :student="student"
        :update-data="binnacle"
        @close="handleUpdateOrCreate"/>
    </div>

    <a-table
      toolbar-column-filter
      :columns="columns_binnacles"
      :source="binnacles"
      class="mb-4"
      :loading="loadings.binnacles"
      :skeleton-count="4" v-else>

      <template #toolbar(search)>
        <h3 class="text-2xl">Bitácoras</h3>
      </template>

      <template #td(actions)="{ item }">
        <a-dropdown v-if="(user.isAdmin || user.isTeacher && user.id === item.user_id) && (item.period_id === enabledPeriod.id)">
          <button class="w-8 h-8 rounded-full border focus:outline-none focus:ring-2 focus:ring-primary">
            <a-icon name="more" class="transform rotate-90" />
          </button>
          <template #menu>
            <a-dropdown-item @click="editBinnacle(item)">
              Actualizar
            </a-dropdown-item>
            <a-dropdown-item @click="remove(item)">
              Eliminar
            </a-dropdown-item>
          </template>
        </a-dropdown>
        <template v-else>
          Sin acceso
        </template>
        <!-- <tem v-if="">
            <a-button icon="trash" icon-color="#FF4600"/>
            <a-button icon="edit" />
        </tem>
        <div v-else>
            Sin acceso
        </div> -->
      </template>

      <template #toolbar>
        <v-select
          class="w-48"
          :loading="loadings.periods"
          :options="periods"
          placeholder="Período"
          :reduce="$0 => $0.value"
          @input="getBinnacles" />

        <button @click="showBinnacles = false;" v-if="showBinnacles" v-tooltip="`Registrar nueva bitácora`" class="border border-gray-200 rounded-md px-4">
          <a-icon name="plus" class="text-gray-700" scale="1.3" />
        </button>
      </template>
    </a-table>
  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex';
import ModalBinnacles from '@/views/binnacles/modalBinnacles';
export default {
  components: {
    ModalBinnacles
  },
  data: () => ({
    showBinnacles: true,
    binnacles: [],
    binnacle: null,
    loadings: {
      binnacles: false,
      periods: false
    },
    periods: [],
    columns_binnacles: [
      { title: 'Fecha', key: 'incidence_date' },
      { title: "Semestre", key: 'course_section', mutate: ({ key }) => `${key.course.code.slice(-1)}`, display: true },
      { title: 'Descripción', key: 'description', mutate: ({ key }, { truncate }) => truncate(key, 150) },
      { title: 'Curso', key: 'course_section', mutate: ({ key }) => `${key.course.title} ${key.section.letter_code}` },
      { title: 'Profesor', key: 'teacher', mutate: ({ key }) => `${key.first_name} ${key.last_name}` },
      { title: 'Especialidad', key: 'course_section.specialization.title' },
      { title: 'Acciones', slot: 'actions', alignment: 'right', columnFilterable: false },
    ],
  }),
  methods: {
    ...mapActions({
      fetchStudentBinnacles: 'students/fetchStudentBinnacles',
      removeStudentBinnacles: 'binnacles/delete',
    }),
    handleUpdateOrCreate() {
      this.showBinnacles = !this.showBinnacles
      this.binnacle = null
      this.getBinnacles()
    },
    editBinnacle(binnacle) {
      this.binnacle = binnacle
      this.showBinnacles = false
    },
    getBinnacles(period){
      const { student } = this.$route.params

      if (student) {
        this.loadings.binnacles = !this.loadings.binnacles
        this.$repository.students
          .binnacles(student, {
            limit: 0,
            with: `student,courseSection.course,courseSection.section,teacher,courseSection.specialization`,
            period
          })
          .then(({ data }) => {
            this.binnacles = data
          })
          .finally(() => this.loadings.binnacles = !this.loadings.binnacles)
      }
    },
    remove(item) {
      this.removeStudentBinnacles(item.id)
        .then(() => {
          this.getBinnacles()
        })
    }
  },
  computed: {
    ...mapState({
      student: ({ students }) => students.current,
      user: ({ session }) => session.user,
    }),
    enabledPeriod() {
      return this.periods.find($0 => $0.enabled) ?? { id: null }
    }
  },
  mounted() {
    this.loadings.periods = !this.loadings.periods
    this.$repository.periods
      .index({ limit: 0, order_by: 'id' })
      .then(({ data }) => {
        this.periods = data.map($0 => ({ label: `${$0.year}-${$0.number}`, value: $0.id, enabled: $0.enabled }))
      })
      .finally(() => this.loadings.periods = !this.loadings.periods)
  }
}
</script>
